import {
    Route,
    Redirect
} from 'react-router-dom';

function PrivateRoute({ children, isAuthenticated, ...rest }) {
    return (
        isAuthenticated ?
            <Route
                {...rest}
            />
            :
            <Redirect
                to={{
                    pathname: '/login',
                }}
            />
    );
}

export default PrivateRoute;